import React, { Component } from "react"
import Switchers from "./Switchers"
import { graphql, StaticQuery } from "gatsby"


class ChevroletList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categories: this.props.categories.edges,
        }
    }

  render() {

    return (
        <>
            <ul
              className="menu-nav uk-subnav uk-subnav-pill uk-visible@m  uk-background-default"
              data-uk-switcher="animation: uk-animation-fade;"
            >
                {this.state.categories.map(
                    trem =>
                    trem.node.id !== "Y2F0ZWdvcnk6MQ==" && (
                        <li key={trem.node.id}>
                            <a
                                className="borderAnimates"
                                data-element={trem.node.id}
                                href={  trem.node.slug}
                              >
                                <span> {trem.node.name} </span>
                            </a>
                        </li>
                    )
                )}
            </ul>
            <ul className="uk-switcher uk-margin uk-padding-small uk-visible@m ">
              {this.state.categories.map(
                cat =>
                  cat.node.id !== "Y2F0ZWdvcnk6MQ==" && (
                    <li key={cat.node.id} id={cat.node.id} className="uk-padding-remove">
                      <Switchers items={cat.node.chevrolets.edges} />
                    </li>
                  )
              )}
            </ul>
            <ul id="navAcordion" className="uk-hidden@m uk-background-default" data-uk-accordion="true">
                  {this.state.categories.map(
                    trem =>
                      trem.node.id !== "Y2F0ZWdvcnk6MQ==" && (
                        <li
                          key={trem.node.id}
                          className="uk-margin-remove uk-padding-remove"
                        >
                          <a className="uk-accordion-title uk-padding-small" href={trem.node.slug}>
                            <span> {trem.node.name} </span>
                          </a>
                          <div className="uk-accordion-content uk-padding-remove ">
                            <Switchers className="uk-padding-small" items={trem.node.chevrolets.edges} />
                          </div>
                        </li>
                      )
                  )}
                </ul>
        </>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        chevrolet {
          categories(where: { hideEmpty: true, orderby: TERM_ID }) {
            edges {
              node {
                id
                name
                count
                chevrolets {
                  edges {
                    node {
                      id
                      chevroletId
                      slug
                      title
                      featuredImage {
                        id
                        sourceUrl
                      }
                      mostrarPalabraDesde
                      versiones {
                        edges {
                          node {
                            precio_version
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={({ chevrolet: { categories } }) => (
      <ChevroletList categories={categories} />
    )}
  />
)
