import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { Provider } from "react-redux"
import { store } from "../redux/_helpers"
import Transition from "../components/transition"
import Header from "../components/header"
import Footer from "../components/footer"
// Styles
import "typeface-montserrat"
import "../assets/scss/bsc.scss"

class Layout extends React.Component {

  render() {

    return (
      <StaticQuery
        query={graphql`
          query {
            chevrolet {
              menu(id: "TWVudToz") {
                id
                menuItems {
                  edges {
                    node {
                      id
                      menuItemId
                      url
                      label
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => {
          const { menuItems } = data.chevrolet.menu
          const { children, location } = this.props

          return (
            <React.Fragment>
              <Provider store={store}>
               <div> <Header menuItems={menuItems} /> </div>
                <div>
                <Transition location={location}>
                    <main>{children}</main>
                    <Footer />
                </Transition>
                </div>
              </Provider>

            </React.Fragment>
          )
        }}
      />
    )
  }
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
