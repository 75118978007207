/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

export const onClientEntry = () => {
  window.onload = () => {
                         (function () {
        var js = document.createElement('script');
        js.type = 'text/javascript';
        js.async = 1;
         js.src = 'https://go.botmaker.com/rest/webchat/p/1QMODJRBSB/init.js';
        document.body.appendChild(js);
    })();}
}