module.exports = {
  pathPrefix: "/",
  title: "Chevrolet Dominicana",
  titleShort: "chevrolet",
  siteUrl:"https://chevrolet.com.do/",
  url: "https://back.chevrolet.com.do/graphql",
  path: "https://back.chevrolet.com.do",
  language: "es",
  logo: "src/assets/img/Chevrolet-logo.png", // Used for SEO and manifest
  description: "",
  author: "Ysidro Almonte - Omd Dominicana",
  uff: "https://back.chevrolet.com.do", // url asiganda para conextar con el plugin de los formularios.
  // Manifest and Progress color
  themeColor: "#0961AD",
  backgroundColor: "#ffffff",
}
