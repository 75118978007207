/* eslint-disable */
import React from "react"
import { Link } from "gatsby"
import MenuItem from "./MenuItem.js"

import logoChevrolet from "../../assets/img/Chevrolet-logo.png"
import Now from "../../assets/svg/nav-search-chat-now.svg"
import SearchIcon from "../../assets/svg/nav-search-contact.svg"
import Search from "../../templates/search"
import ChevroletList from "../ChevroletList/"
import Faq from "../../assets/svg/nav-search-faq.svg"

//import { OffCanvas } from "../ui";
//import Calculators from "../calculators";


class Header extends React.Component {


    componentDidMount() {
      if ( typeof window !== 'undefined' ) {

        let dataNavScroll = Array.from( document.querySelectorAll('a[data-nav-scroll]') )
        dataNavScroll.map( scroll => scroll.addEventListener('click', function(e) {

            console.log(this.innerText);
            document.querySelector("#labelIconMenu").innerText = this.innerText;

        }) )

      }
    }


    componentWillUpdate(){
        window.UIkit.offcanvas("#drop-menu-content").hide()
        window.UIkit.offcanvas("#offcanvas-nav").hide()
        window.UIkit.offcanvas("#offcanvas-search").hide()
        window.UIkit.offcanvas("#offcanvas-cards").hide()
        window.UIkit.offcanvas("#searchNav").hide()

    }

  render() {

    const menuItems = this.props.menuItems.edges

    return (
      <nav
        id="top-menu"
        className="uk-navbar-container chevrolet-bg-white uk-padding-top"
        data-uk-navbar="true"
      >
        <div className="uk-navbar-left">
          <a href="/" className="uk-navbar-item uk-logo">
            <img
              src={logoChevrolet}
              alt="Chevrolet" />
          </a>

          <ul className="uk-navbar-nav uk-visible@m">
            {menuItems.map(item => (
              <MenuItem key={item.node.id} items={item} />
            ))}
          </ul>

          <div
            id="offcanvas-nav"
            data-uk-offcanvas="overlay: true;flip: true;"
            className="chevrolet-bg-white"
          >
            <div className="uk-offcanvas-bar uk-background-default uk-flex uk-flex-column chevrolet-bg-white">
              <button
                className="uk-offcanvas-close"
                type="button"
                data-uk-close="true"
              >
                <span className="far fa-times-circle" />
              </button>
              <ul className="uk-list uk-nav uk-nav-default uk-list uk-list-divider">
                {menuItems.map(item => (
                  <MenuItem key={item.node.id} items={item} />
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div className="uk-navbar-right" >

            <ul className="uk-navbar-nav">
                <li>
                    <a
                      className="uk-hidden@m"
                      data-uk-toggle="target: #offcanvas-cards"
                    >
                      Vehículos
                      <i className="uk-icon uk-margin-small-right fa fa-chevron-down" />
                    </a>

                    <div
                      id="offcanvas-cards"
                      data-uk-offcanvas="overlay: true;flip: true;"
                      className="chevrolet-bg-white"
                    >
                      <div className="uk-offcanvas-bar uk-background-default uk-l uk-margin-remove uk-padding-remove">
                        <button
                          className="uk-offcanvas-close far fa-times-circle"
                          type="button"
                          data-uk-close="true"
                        />
                        <ChevroletList />
                      </div>
                    </div>
                </li>
                <li className="uk-visible@m">
                    <a href="#">
                      <i className="uk-icon uk-margin-small-right fas fa-search" />
                      <span >Buscar</span> &nbsp;{" "}
                      <i className="uk-icon uk-margin-small-right fa fa-chevron-down" />
                    </a>

                    <div
                      id="searchNav"
                      className="uk-navbar-dropdown chevrolet-bg-white"
                      data-uk-dropdown="mode: click"
                    >
                      <div className="uk-container uk-container-expand">
                        <Search />
                        <div className="uk-grid uk-margin-small-top uk-padding-bottom">
                          <div className="uk-width-1-2@m uk-width-1-1@s uk-margin">
                            <h4>BÚSQUEDAS POPULARES</h4>

                          </div>

                          <div className="uk-width-1-2@m uk-width-1-1@s">
                            <h4>CENTRO DE AYUDA</h4>
                            <div
                              id="help"
                              className="uk-grid-match uk-text-center uk-child-width-1-3@m uk-child-width-1-2@s uk-padding-bottom uk-flex uk-flex-left"
                            >
                              <div className="uk-padding-small">
                                <div className="uk-card  uk-card-body uk-padding-small">
                                  <Link to="/contacto">
                                    <img src={SearchIcon} alt="Contactanos" />

                                    <p className="uk-padding-remove uk-margin-remove">
                                      <strong>
                                        <small>Contáctanos</small>
                                      </strong>
                                    </p>
                                  </Link>
                                </div>
                              </div>
                              <div className="uk-padding-small">
                                <div className="uk-card  uk-card-body uk-padding-small">
                                  <Link to="/preguntas-frecuentes">
                                    <img src={Faq} alt="Preguntas Frecuentes" />
                                    <p className="uk-padding-remove uk-margin-remove">
                                      <strong>
                                        <small>Preguntas Frecuentes</small>
                                      </strong>
                                    </p>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </li>
                <li  className="uk-hidden@m">


                    <a
                      className="uk-hidden@m"
                      data-uk-toggle="target: #offcanvas-search"
                    >
                      <i className="uk-icon uk-margin-small-right fas fa-search" />
                      <i className="uk-icon uk-margin-small-right fa fa-chevron-down" />
                    </a>

                    <div
                      id="offcanvas-search"
                      data-uk-offcanvas="overlay: true;flip: true;"
                      className="chevrolet-bg-white"
                    >
                      <div className="uk-offcanvas-bar uk-background-default uk-l uk-margin-remove uk-padding-remove">
                        <button
                          className="uk-offcanvas-close far fa-times-circle"
                          type="button"
                          data-uk-close="true"
                        />
                      <div className="uk-container uk-container-expand">
                        <Search />
                        <div className="uk-grid">
                          <div className="uk-width-1-2@m uk-width-1-1@s uk-margin">
                            <h4>BÚSQUEDAS POPULARES</h4>
                          </div>

                          <div className="uk-width-1-2@m uk-width-1-1@s uk-margin ">
                            <h4>CENTRO DE AYUDA</h4>
                            <div
                              id="help"
                              className="uk-grid-match uk-text-center uk-child-width-1-2@m  uk-padding-bottom uk-flex uk-flex-center"
                            >
                              <div className="uk-padding-small">
                                <div className="uk-card  uk-card-body uk-padding-small">
                                  <Link to="/contacto">
                                    <img src={SearchIcon} alt="Contactanos" />

                                    <p>
                                      <strong>
                                        <small>Contáctanos</small>
                                      </strong>
                                    </p>
                                  </Link>
                                </div>
                              </div>
                              <div className="uk-padding-small">
                                <div className="uk-card  uk-card-body uk-padding-small">
                                  <Link to="/preguntas-frecuentes">
                                    <img src={Faq} alt="Preguntas Frecuentes" />
                                    <p>
                                      <strong>
                                        <small>Preguntas Frecuentes</small>
                                      </strong>
                                    </p>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    </div>
                </li>
            </ul>

            <button
              type="button"
              className="uk-hidden@m uk-button uk-button-default"
              data-uk-toggle="target: #offcanvas-nav"
            >
              <span className="uk-icon uk-margin-small-right fas fa-bars" />
            </button>

        </div>

      </nav>
    )
  }
}

export default Header
