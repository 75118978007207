import React from "react"
import { Link } from "gatsby"
import ChevroletList from "../ChevroletList/"
import {path} from "../../../config/app.js"

const MenuItem = items => {
  const item = items.items.node
  const url = item.url
    .substring(path)
    .split("/")
    .splice(-2)[0]

  if (item.menuItemId === 376) {
    return (
      <li className="uk-visible@m" key={item.menuItemId}>
        <a href={url}>
          {item.label}{" "}
          <i className="uk-icon uk-margin-small-right fa fa-chevron-down" />
        </a>
        <div
          id="drop-menu-content"
          className="uk-navbar-dropdown"
          data-uk-drop="mode: click"
        >
          <ChevroletList />
        </div>
      </li>
    )
  }
  return (
    <li key={item.menuItemId}>
      <Link to={url}> {item.label} </Link>
    </li>
  )
}
export default MenuItem
