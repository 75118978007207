import React, { Component } from "react"
import { Link,graphql, StaticQuery } from "gatsby"

//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
//import { envelope, phone } from "@fortawesome/fontawesome-common-types"
//import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons"
// import FA from "react-fontawesome"
import fnr from "../../assets/img/fnr.png"
import lsdm from "../../assets/img/logo-sdm.png"
//import logo from "../../assets/svg/logo-white.svg";

class Footer extends Component {
  render() {
    return (
      <footer className="uk-padding uk-margin-large-top">
        <div className="uk-container uk-container-expand">
          <div
            className="uk-grid uk-margin-top uk-margin-bottom"
            data-uk-grid="true"
          >
            <div className="uk-width-1-3@m uk-width-1-1@s">
              <h3>CONTACTOS:</h3>
              <p>
               <a target="_blank"
                  rel="noopener noreferrer" href="email:contacto@sdm.com.do">  <i className="uk-text-large far fa-envelope"></i>  contacto@sdm.com.do</a>
              </p>
              <p>  <a target="_blank"
                  rel="noopener noreferrer" href="tel:8095403800"> <i className="uk-text-large fas fa-phone"></i> (809) 540 3800</a></p>
              <p>
              <a target="_blank"
                  rel="noopener noreferrer" href="https://web.whatsapp.com/send/?phone=8295215151" className="uk-visible@s"> <i className="uk-text-large fab fa-whatsapp"></i> (829) 521 5151</a>
               <a target="_blank"
                  rel="noopener noreferrer" href="whatsapp://send//?phone=8295215151" className="uk-hidden@s"> <i className="uk-text-large fab fa-whatsapp" ></i> (829) 521 5151</a></p>
            </div>

            <div className="uk-width-1-3@m uk-width-1-1@s">
              <h3>HERRAMIENTAS DE COMPRA:</h3>
              <ul className="uk-list">
                {this.props.menus.menuItems.edges.map(li => (
                  <li key={li.node.id}>
                    <a
                      href={
                        li.node.url
                          .substring("http://localhost:8088/")
                          .split("/")
                          .splice(-2)[0]
                      }
                      alt={li.node.label}
                    >
                      {li.node.label}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            <div className="uk-width-1-3@m uk-width-1-1@s">
              <h3>SIGUENOS EN</h3>
              <div className="uk-flex uk-flex-left uk-grid" uk-grid="true">
                <a
                  className="ul-buttom uk-buttom-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/ChevroletRD"
                >

                  <span className="uk-text-large fab fa-facebook-f" />
                </a>
                <a
                  className="ul-buttom uk-buttom-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://twitter.com/ChevroletRD"
                >

                  <span className="uk-text-large fab fa-twitter" />
                </a>
                <a
                  className="ul-buttom uk-buttom-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/chevroletrd"
                >
                 <span className="uk-text-large fab fa-instagram" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="uk-container uk-container-expand">
          <div
            className="uk-grid uk-margin-top uk-margin-bottom"
            data-uk-grid="true"
          >
            <div className="uk-width-expand@m">
              <img
                src={lsdm}
                alt="logo santo domingo motoros"
                className="uk-margin-right"
                width="35px"
              />
              Con la garantía de <strong>SANTO DOMINGO MOTORS</strong>
              <br />
              <br />
              <small>
              <Link to="/contacto" className="uk-link-muted">Contáctanos</Link> |
               <Link to="/preguntas-frecuentes" className="uk-link-muted">Preguntas frecuentes</Link> |
               <a href="https://www.chevrolet.com/"  className="uk-link-muted" rel="noopener noreferrer" target="_blank">Chevrolet Internacional</a>

                 <br /> Sitios GM Información de Copyright y marca
                comercial Declaración de privacidad de GM Información importante
                Guía del usuario
              </small>
              <br />
              <small>
                *El precio de venta sugerido por el fabricante no incluye costo
                del flete, impuesto, título, licencia, cargos del concesionario
                y equipos opcionales. Haz clic aquí para ver los costos de flete
                de todos los vehículos Chevrolet.
              </small>
            </div>
            <div className="uk-width-1-1 uk-width-1-4@m">
              <img src={fnr} alt="logo santo domingo motoros"  />
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        chevrolet {
          menu(id: "TWVudToxOQ==") {
            id
            menuItems {
              edges {
                node {
                  id
                  menuItemId
                  url
                  label
                }
              }
            }
          }
        }
      }
    `}
    render={({ chevrolet: { menu } }) => <Footer menus={menu} />}
  />
)
